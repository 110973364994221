<template>
	<div v-if="$amp" class="concentric--amp">
		<amp-position-observer
			:target="'concentric-' + design"
			intersection-ratios="0"
			:on="`scroll:concentric-animation--${design}.seekTo(percent=event.percent)`"
			layout="nodisplay"
		>
		</amp-position-observer>
		<amp-animation :id="'concentric-animation--' + design" layout="nodisplay">
			<!-- eslint-disable-next-line vue/no-v-html -->
			<script type="application/json" v-html="ampAnimation"></script>
		</amp-animation>
		<component :is="`c-${design}`" :id="'concentric-' + design" ref="concentric" />
	</div>
	<div v-else-if="stickySituation" class="concentric-mask pin-top-0 pin-right-0 pin-bottom-0 pin-left-0">
		<div class="wrapper">
			<component :is="`c-${design}`" ref="concentric" />
		</div>
	</div>
	<div v-else>
		<component :is="`c-${design}`" ref="concentric" :data-big="isBig" />
	</div>
</template>

<script>
	export default {
		name: 'CConcentric',
		components: {
			CHome: () => import(/* webpackChunkName: "concentricSvg" */ '@cognitoforms/shared-components/src/assets/graphics/concentric/home.svg?concentric'),
			CPricing: () => import(/* webpackChunkName: "concentricSvg" */ '@cognitoforms/shared-components/src/assets/graphics/concentric/pricing.svg?concentric'),
			CPowerful: () => import(/* webpackChunkName: "concentricSvg" */ '@cognitoforms/shared-components/src/assets/graphics/concentric/powerful.svg?concentric'),
			CIdea: () => import(/* webpackChunkName: "concentricSvg" */ '@cognitoforms/shared-components/src/assets/graphics/concentric/idea.svg?concentric'),
			CCeres: () => import(/* webpackChunkName: "concentricSvg" */ '@cognitoforms/shared-components/src/assets/graphics/concentric/ceres.svg?concentric'),
			CBlog: () => import(/* webpackChunkName: "concentricSvg" */ '@cognitoforms/shared-components/src/assets/graphics/concentric/blog.svg?concentric'),
			CBlogMobile: () => import(/* webpackChunkName: "concentricSvg" */ '@cognitoforms/shared-components/src/assets/graphics/concentric/blog-mobile.svg?concentric'),
			CBlogTop: () => import(/* webpackChunkName: "concentricSvg" */ '@cognitoforms/shared-components/src/assets/graphics/concentric/blog-top.svg?concentric'),
			CGuides: () => import(/* webpackChunkName: "concentricSvg" */ '@cognitoforms/shared-components/src/assets/graphics/concentric/guides.svg?concentric'),
			CIndustries: () => import(/* webpackChunkName: "concentricSvg" */ '@cognitoforms/shared-components/src/assets/graphics/concentric/industries.svg?concentric'),
			CFaq: () => import(/* webpackChunkName: "concentricSvg" */ '@cognitoforms/shared-components/src/assets/graphics/concentric/faq.svg?concentric'),
			CFaq2: () => import(/* webpackChunkName: "concentricSvg" */ '@cognitoforms/shared-components/src/assets/graphics/concentric/faq2.svg?concentric'),
			CLight: () => import(/* webpackChunkName: "concentricSvg" */ '@cognitoforms/shared-components/src/assets/graphics/concentric/light.svg?concentric'),
			CSpruce: () => import(/* webpackChunkName: "concentricSvg" */ '@cognitoforms/shared-components/src/assets/graphics/concentric/spruce.svg?concentric'),
			CSimple: () => import(/* webpackChunkName: "concentricSvg" */ '@cognitoforms/shared-components/src/assets/graphics/concentric/simple.svg?concentric'),
			CNewsies: () => import(/* webpackChunkName: "concentricSvg" */ '@cognitoforms/shared-components/src/assets/graphics/concentric/newsies.svg?concentric'),
			CAiFormGeneration: () => import(/* webpackChunkName: "concentricSvg" */ '@cognitoforms/shared-components/src/assets/graphics/concentric/ai-form-generation.svg?concentric'),

			CFooter: () => import(/* webpackChunkName: "concentricSvg" */ '@cognitoforms/shared-components/src/assets/graphics/concentric/footer.svg?concentric'),

			CGear: () => import(/* webpackChunkName: "concentricSvg" */ '@cognitoforms/shared-components/src/assets/graphics/concentric/gear.svg?concentric'),
			CGearTop: () => import(/* webpackChunkName: "concentricSvg" */ '@cognitoforms/shared-components/src/assets/graphics/concentric/gear-top.svg?concentric'),
			CLogo: () => import(/* webpackChunkName: "concentricSvg" */ '@cognitoforms/shared-components/src/assets/graphics/concentric/logo.svg?concentric'),
			CLogoTop: () => import(/* webpackChunkName: "concentricSvg" */ '@cognitoforms/shared-components/src/assets/graphics/concentric/logo-top.svg?concentric'),
			CVinyl: () => import(/* webpackChunkName: "concentricSvg" */ '@cognitoforms/shared-components/src/assets/graphics/concentric/vinyl.svg?concentric')
		},
		props: {
			design: {
				type: String,
				default: 'pricing'
			},
			stickySituation: {
				type: Boolean,
				default: false
			},
			isBig: {
				type: Boolean,
				default: true
			}
		},
		data() {
			return {
				ampAnimation: JSON.stringify([
					{
						'selector': `#concentric-${this.design} .concentric__container > *:nth-child(even), #concentric-${this.design} .clockwise`,
						'duration': '5s',
						'fill': 'forwards',
						'keyframes': [
							{ 'transform': 'rotate(170deg)' },
							{ 'transform': 'rotate(530deg)' }
						]
					},
					{
						'selector': `#concentric-${this.design} .concentric__container > *:nth-child(odd), #concentric-${this.design} .counterclockwise`,
						'duration': '5s',
						'fill': 'forwards',
						'keyframes': [
							{ 'transform': 'rotate(-190deg)' },
							{ 'transform': 'rotate(-550deg)' }
						]
					}
				])
			};
		},
		head() {
			return {
				htmlAttrs: {
					class: [
						...(this.isBig ? ['has-concentric'] : []),
						...(this.stickySituation ? ['concentric-sticky-situation'] : [])
					]
				}
			};
		}
	};
</script>

<style lang="scss">
.has-concentric [id='app'] {
	overflow: hidden;
	overscroll-behavior: contain;
}

.concentric,
.concentric--amp {
	position: absolute;
	left: 50%;
	top: 50%;
	z-index: $z-1;
	transform: translate(-50%, -50%);
	pointer-events: none;

	&[viewBox~='3000'] {
		width: 250vw;
		max-width: 3000px;
		height: 250vw;
		max-height: 3000px;
	}

	image {
		transform: translate(-250px, -250px);
	}

	.concentric__container,
	.concentric__container > * {
		transform-origin: center;
	}

	&--amp {
		z-index: $z-1;
	}
}

// If you need to position an element sticky you wil need to position the concentric design via this method.
// The design is positioned relative to the wrapper.
// You can position the design 100px down from top of wrapper and 100px to the right of the left edge like this:
// top: 100px; transform: translate(calc(-50% + 100px), -50%);
.concentric-sticky-situation {

	[id='app'] {
		overflow: visible;
	}

	.concentric-mask {
		overflow: hidden;
	}
}

</style>